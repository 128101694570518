import styled from "styled-components";

import { styles as typographyStyles } from "./Typography";

export const H1 = styled.h1`
  ${typographyStyles.H1}
`;

export const H2 = styled.h2`
  ${typographyStyles.H2}
`;

export const H3 = styled.h3`
  ${typographyStyles.H3}
`;

export const H4 = styled.h4`
  ${typographyStyles.H4}
`;

export const H5 = styled.h5`
  ${typographyStyles.H5}
`;

export const H6 = styled.h6`
  ${typographyStyles.H6}
`;

export const Body = styled.p`
  ${typographyStyles.Body}
`;

export const Caption = styled.p`
  ${typographyStyles.Caption}
`;

export const Overline = styled.p`
  ${typographyStyles.Overline}
`;

export const Button = styled.button`
  ${typographyStyles.ButtonLabel}
`;
